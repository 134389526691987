import { RouteRecordRaw } from "vue-router";
import MainLayout from "@src/layout/MainLayout.vue"

import { routes as analysisRouter } from "./modules/analysis";
import { routes as productsRouter } from "./modules/products";
import { routes as omsRouter } from "./modules/oms";
import { routes as disbursementRouter } from "./modules/disbursement";
import { routes as customerRouter } from "./modules/customer";
import { routes as discountRouter } from "./modules/discount";
import { routes as accountRouter } from "./modules/account";
import { routes as settingRouter } from "./modules/setting";

const routes:RouteRecordRaw[] = [
  {
    path: "/noPermission",
    name: "noPermission",
    component: () => import("@src/view/login/Permission.vue"),
  },
  
  {
    path: "/login",
    name: "login",
    component: () => import("@src/view/login/index.vue"),
    redirect: "/login/home",
    children: [
      {
        path: "home",
        name: "LoginHome",
        component: () => import("@src/view/login/Home.vue"),
      },
      // {
      //   path: "admin",
      //   name: "adminHome",
      //   component: () => import("@src/view/login/Home.vue"),
      // },
    ],
  },

  {
    path: "/",
    redirect: "/analysis/home",
    component: MainLayout,
    children: [
      ...analysisRouter,
      ...productsRouter,
      ...omsRouter,
      ...disbursementRouter,
      ...customerRouter,
      ...discountRouter,
      ...accountRouter,
      ...settingRouter,
    ],
  },
]
export default routes