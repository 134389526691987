<template>
  <div class="orderProductItem">
    <div class="flex justify-between">
      <div class="flex">
        <p
          class="mr-5 text-lg flex-shrink-0"
          :class="isCancelOrder ? 'text-gray-400' : 'text-gray-700'"
        >
          {{ product.quantity }}x
        </p>
        <div>
          <h3
            class="text-lg font-bold"
            :class="
              isCancelOrder || product.groupStatusId === 'ITEM_GROUP_CANCELED'
                ? 'text-gray-400'
                : 'text-gray-700'
            "
          >
            {{ product.productName }}
          </h3>
          <p class="text-sm text-gray-400">{{ product.internalName }}</p>
          <template v-if="product.modifiers && product.modifiers.length">
            <div
              v-for="mixItem in product.modifiers"
              :key="mixItem.name"
              class="text-sm leading-6 text-gray-400 w-full break-all"
            >
              · {{ mixItem.name }}
            </div>
          </template>
        </div>
      </div>

      <div class="text-lg flex-shrink-0">
        <p v-if="product.myType === 'gift'">
          {{ $t("oms,orderInfo.free") }}
        </p>
        <template v-else>
          <div class="text-right">
            <div v-if="product.groupStatusId === 'ITEM_GROUP_CANCELED'">
              <StatusLabel
                typeName="order"
                :keyName="'ORDER_CANCELLED'"
              ></StatusLabel>
            </div>
          </div>

          <template v-if="product.groupStatusId !== 'ITEM_GROUP_CANCELED'">
            <p v-if="price">
              {{ $n(price, "currency", "id-ID") }}
            </p>
            <p
              v-if="delPrice && delPrice != price"
              class="line-through text-gray-400 text-xs text-right"
            >
              {{ $n(delPrice, "currency", "id-ID") }}
            </p>
          </template>
          <p v-else>
            {{ $n(delPrice, "currency", "id-ID") }}
          </p>
        </template>

        <!-- <p
        class="line-through text-gray-400"
        v-if="product.baseSingleTotal != product.finalSingleTotal"
      >
        {{ $n(product.baseSingleTotal, "currency", "id-ID") }}
      </p> -->
        <div class="text-right">
          <StatusMark
            v-if="orderType == 'FULL_DINE_IN'"
            :status="product.groupStatusId"
          >
          </StatusMark>
        </div>
      </div>
    </div>
    <p v-if="product.comment" class="px-2 py-0.5 bg-gray-50 my-4 rounded-sm">
      <i class="menu-iconfont menu-Notes_1 text-gray-400 mr-1.5"></i>
      <span class="text-gray-700 text-sm">{{ product.comment }}</span>
    </p>
    <PkgProductItem :product="product" />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import StatusMark from "./StatusMark.vue";
import PkgProductItem from "./PkgProductItem.vue";
import StatusLabel from "@components/StatusLabel.vue";
import { useProductPrice } from "@src/utils/usePriceCalc";

const { t } = useI18n();
const props = defineProps({
  product: {
    type: Object as any,
  },
  orderType: {
    type: String,
    default: "",
  },
  isCancelOrder: {
    type: Boolean,
    default: false,
  },
});
const { delPrice, price } = useProductPrice(computed(() => props.product));
</script>
<style lang="scss" scoped>
.orderProductItem {
  padding-bottom: 20px;
}
</style>
