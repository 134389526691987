<template>
  <div class="px-4 bg-white sticky top-0 z-10 mb-4 rounded-sm">
    <div 
      class="pt-3 flex items-center"
      :class="isHasSlot ? 'pb-2.5' : 'pb-3'"
    >
      <template v-if="props.isBack">
        <div class="cursor-pointer flex items-center" @click="handleBackPrev">
          <i class="menu-iconfont menu-Back mr-2"></i>
          <span class="font-bold">{{ $t(props.title) }} </span>
          <slot name="extra"></slot>
        </div>
      </template>

      <template v-else>
        <span class="font-bold text-sapphire-700 text-base leading-6">
          {{ $t(props.title) }}
        </span>
      </template>
    </div>
    <template v-if="isHasSlot">
      <div class="h-0.5 bg-gray-100"></div>
      <div class="py-4">
        <slot></slot>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import type { VNode } from "vue"

interface propsType {
  isBack?:boolean
  title: string
  backPath?: string
}
const props = withDefaults(defineProps<propsType>(), {
  isBack: false,
  title: "common.noData",
  backPath: "/"
});

const router = useRouter();

const slots = useSlots()
const isHasSlot = computed(() => {
  if(slots && slots.default) {
    const VNode: VNode[] = slots.default()
    // 排除注释 Symbol(Comment)
    return VNode.some(vNode => vNode.type.toString() !== 'Symbol(Comment)')
  }
  return false
})

const handleBackPrev = () => {
  router.push({ path: props.backPath })
}
</script>