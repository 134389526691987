import { App } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { createRouterGuards } from './guards';

import { routerTreeShake } from "./until";
import { getStoreInfo } from "../http/login";
import { off } from "process";

const router = createRouter({
  history: createWebHistory(),
  routes
});

export function setupRouter(app: App) {
  app.use(router);
  // 创建路由守卫
  createRouterGuards(router);
}

export default router;
