<template>
  <div v-if="showPkg || showPromo" class="orderPkg">
    <template v-if="more">
      <div v-if="showPromo" class="pkg-promo">
        <div bold class="pkg-promo-tile">{{ promoTile }}</div>
        <div
          v-for="(promo, key) in promoList"
          :key="key"
          class="pkg-promo-item"
        >
          <span class="item-count pt-1">x{{ promo.count * product.quantity }}</span>
          <div break class="item-info">{{ productName }}</div>
          <div class="item-price">
            <span>{{
              $n(promo.price * promo.count, "currency", "id-ID")
            }}</span>

            <span v-if="!promo.isNormal" class="del">
              {{ $n(promo.normalPrice * promo.count, "currency", "id-ID") }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="showPkg" class="pkg-groups">
        <div class="pkg-groups-item">
          <div class="item-tile">{{ $t("oms.bundlingItem") }}</div>
          <div class="item-prods">
            <div v-for="it in mainList" :key="it.productId">
              <div class="prod">
                <span class="prod-count">x{{ it.netQuantity * product.quantity }}</span>
                <div class="prod-m">
                  <p class="prod-tile">
                    {{ it.productName }}
                  </p>
                  <p class="prod-subtile">
                    <template v-for="(modif, i) in it.modifiers || []">
                      .{{ modif.name }}
                      {{ i === it.modifiers.length - 1 ? "" : "/" }}
                    </template>
                  </p>
                </div>
              </div>
              <p
                v-if="it.comment"
                class="px-2 py-0.5 bg-gray-50 my-4 rounded-sm"
              >
                <i class="menu-iconfont menu-Notes_1 text-gray-400 mr-1.5"></i>
                <span class="text-gray-700 text-sm">{{ it.comment }}</span>
              </p>
              <div class="prod-r">
                <div class="prod-price" />
                <!-- 主商品modifier价格 -->
                <!-- <span v-if="sumModifPrice(it) > 0" class="prod-price">
                  {{ $n(sumModifPrice(it), 'currency', 'id-ID') }}
                </span> -->
              </div>
            </div>
          </div>
        </div>
        <div v-for="(item, i) in additional" :key="i" class="pkg-groups-item">
          <div class="item-tile">
            {{ $t("oms.additional") }} {{ item.groupName }}
          </div>
          <div class="item-prods">
            <div v-for="it in item.items" :key="it.productId">
              <div class="prod">
                <div class="prod-count">x{{ it.netQuantity * product.quantity }}</div>
                <div class="prod-m">
                  <p class="prod-tile">{{ it.productName }}</p>
                  <p class="prod-subtile">
                    <template v-for="(modif, idx) in it.modifiers || []">
                      .{{ modif.name }}
                      {{ idx === it.modifiers.length - 1 ? "" : "/" }}
                    </template>
                  </p>
                </div>
              </div>
              <p
                v-if="it.comment"
                class="px-2 py-0.5 bg-gray-50 my-4 rounded-sm"
              >
                <i class="menu-iconfont menu-Notes_1 text-gray-400 mr-1.5"></i>
                <span class="text-gray-700 text-sm">{{ it.comment }}</span>
              </p>

              <div class="prod-r">
                <!-- <span class="prod-price">
                  {{ $n(it.finalPrice * it.netQuantity, 'currency', 'id-ID') }}
                </span> -->
                <!-- <span v-if="sumModifPrice(it) > 0" class="prod-price">
                  {{ $n(sumModifPrice(it), 'currency', 'id-ID') }}
                </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="showSeeBtn" class="pkg-btn" @click="more = !more">
      {{ seeAllText }}
      <span v-if="!more" class="menu-iconfont">&#xe6de;</span>
      <span v-else class="menu-iconfont">&#xe6df;</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useProductPrice } from "@src/utils/usePriceCalc";
const isPkg = (type) => {
  return type === "PACKAGE";
};

const props = defineProps({
  product: {
    type: Object,
    default() {
      return {};
    },
  },
});
const { t } = useI18n();
const more = ref(false);

const _prod = computed(() => props.product);
const {
  price,
  delPrice,
  promoTile,
  showPromo: _showPromo,
  promoList,
} = useProductPrice(_prod);
const isCanceled = computed(() => {
  return _prod.value.groupStatusId === "ITEM_GROUP_CANCELED";
});
const showPromo = computed(() => _showPromo.value && !isCanceled.value);
const showPkg = computed(() => isPkg(props.product.groupTypeId));
const showSeeBtn = computed(() => {
  if (showPkg.value) return true;
  return showPromo.value;
});
const seeAllText = computed(() => {
  if (more.value) return t("oms.hideAllItems");
  if (showPromo.value && !isCanceled.value) return promoTile.value;
  if (showPkg.value) return t("oms.seeAllItems");
  return promoTile.value;
});
const productName = computed(() => props.product.productName);
const mainList = computed(() => props.product.baseGroup?.items || []);
const additional = computed(() => {
  const options = props.product.optionGroups || [];
  return options;
});

function sumModifPrice(product) {
  return (
    product.modifiers.reduce((acc, cur) => acc + cur.basePrice, 0) *
    product.netQuantity
  );
}
</script>

<style lang="scss" scoped>
.orderPkg {
  .pkg-btn {
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    text-align: center;
    color: rgba(107, 114, 128, 1);
    padding: 4px;
    cursor: pointer;
  }

  .pkg-promo {
    border-top: 1px solid rgba(229, 231, 235, 1);
    padding: 10px 0;
    &-tile {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
      color: rgba(156, 163, 175, 1);
    }
    &-item {
      display: flex;
    }
    .item-count {
      min-width: 40px;
      // align-self: flex-start;
    }
    .item-info {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: #374151;
      flex: 1;
    }
    .item-price {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      font-weight: 800;
      .del {
        font-weight: normal;
        text-decoration: line-through;
        color: #374151;
      }
    }
  }
  .pkg-groups {
    border-top: 1px solid rgba(229, 231, 235, 1);
    padding: 10px 0;
    margin-top: 10px;
    &-tile {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
      color: rgba(156, 163, 175, 1);
    }
    &-item {
      .item-tile {
        font-size: 12px;
        color: #6b7280;
        margin-bottom: 12px;
      }
      .item-prods {
        .prod {
          position: relative;
          min-height: 35px;
          display: flex;
          & + .prod {
            margin-top: 10px;
          }
          .prod-m {
            flex: 1;
            padding-right: 15px;
          }
          .prod-r {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;
          }
          &-tile {
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            color: #374151;
            flex: 1;
          }
          &-count,
          &-subtile {
            font-size: 12px;
            color: #6b7280;
          }
          &-count {
            min-width: 40px;
            align-self: flex-start;
            font-size: 14px;
          }
          &-price {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
