import services from "./index";
import { menuProd, sellerPreUrl, menuPermissionBaseUri } from "./config";

// ================ 检查是否为三方 ================
export const batchQueryStorePosInfo = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/batchQueryStorePosInfo`, data);
};
// ================ category ================
export const addNewCategory = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/newCategory`, data);
};

export const updataNewCategory = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/updateCategories`, data);
};

export const deleteNewCategory = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/deleteCategories`, data);
};


// ================ products ================
export const getCategorieList = (data: any) => {
  return services.post(`/${menuProd}/pms/categories`, data);
};

export const getProductList = (data: any) => {
  return services.post(`/${menuProd}/pms/mainProducts`, data);
};

export const deleteProductModifier = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/deleteProductModifier`, data);
};

export const updateProductModifier = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/updateProductModifier`, data);
};

export const updateProductStatus = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/updateProductStatus`, data);
};

// ================ add products ================
export const getStoreAllCategory = (data: any) => {
  return services.get(`/${menuProd}/product/getStoreAllCategory`, {params: data});
};

export const apiProductManage = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/productManage`, data);
};

export const getProductDetail = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/productDetail`, data);
};

export const getUploadImage = (data?: any) => {
  return services.get(`/${menuPermissionBaseUri}/oss/sts/putToken`, data);
};


// ================ modifier ================

export const getStoreModifierTemplate = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/storeModifierTemplate`, data);
};

export const getModifierTemplatesList = (data: any) => {
  return services.post(`/${menuProd}/pms/modifierTemplates`, data);
};

export const updateModifierTemplate = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/updateModifierTemplates`, data);
};

export const getModifierList = (data: any) => {
  return services.post(`/${menuProd}/pms/modifiers`, data);
};

export const getAssignedProductList = (data: any) => {
  return services.post(`/${menuProd}/pms/assignedProductList`, data);
};

export const unAssignedModifierProducts = (data: any, headers: any) => {
  return services.post(`/${sellerPreUrl}/seller/unAssignedModifierProducts`, data, {headers: headers});
};

export const batchAssignModifiers = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/batchAssignModifiers`, data);
};

export const deleteModifierTemplates = (data: any) => {
  return services.post(`/${sellerPreUrl}/seller/deleteModifierTemplates`, data);
};
