<template>
  <div class="bg-white mx-auto h-12">
    <div class="max-w-1440 m-auto flex justify-between">
      <img
        src="https://static.heroes.horego.com/menu/horego_frame_order.svg"
        class="h-8 ml-4 mt-2"
        @click="clickImg"
      />
      <div class="flex items-center mr-5">
        <el-dropdown
          trigger="click"
          :hide-on-click="false"
          class="mr-3"
          popper-class="header_dropdown_popper"
        >
          <span class="el-dropdown-link">
            <el-icon class="el-icon--right">
              <span class="icon menu-iconfont">&#xe622;</span>
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item style="padding: 12px">
                <a href="http://biz.horego.com" target="_blank">
                  <img
                    src="https://static.heroes.horego.com/menu-pc/HoregoPlace.svg"
                  />
                </a>
              </el-dropdown-item>
              <el-dropdown-item style="padding: 12px">
                <a href="http://merchant.horego.com/" target="_blank">
                  <img
                    src="https://static.heroes.horego.com/menu-pc/HoregoOrder.svg"
                  />
                </a>
              </el-dropdown-item>
              <el-dropdown-item style="padding: 12px">
                <a href="http://supply.horego.com" target="_blank">
                  <img
                    src="https://static.heroes.horego.com/menu-pc/HoregoSupply.svg"
                  />
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span class="text-black mr-4 text-sm">
          {{ storeInfo.name || "Horego Biz Dashboard" }}
        </span>
        <img
          v-show="storeInfo.logo"
          :src="storeInfo.logo"
          :class="[
            'w-8',
            'h-8',
            'rounded-full',
            'border',
            'border-white',
            'border-solid',
            'box-border',
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "@src/store/index";
import { useI18n } from "vue-i18n";

const store = useStore();
const storeInfo: any = computed(() => {
  if (store.userStores?.length === 1) {
    return store.userStores[0];
  }
  return {};
});
const { locale } = useI18n();
let i = 0;
function clickImg() {
  i += 1;
  if (i % 2 == 0) {
    locale.value = "en-US";
  } else {
    locale.value = "id-ID";
  }
  store.language = locale.value;
}
</script>

<style lang="scss">
.header_dropdown_popper {
  padding: 1px 6px !important;
  li.el-dropdown-menu__item {
    padding: 0 !important;
    &:hover {
      border-radius: 8px;
      background: #f5f5f5;
    }
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 12px;
    }
  }
}
</style>
