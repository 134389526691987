import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "disbursement",
    component: BasicLayout,
    redirect: "/disbursement/home",
    meta: {
      permissionKey: "PC_Disbursement",
      navInfo: {
        icon: "menu-Disbursement",
        title: "common.sidebar.disbursement", // i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "DisbursementList",
        component: () => import("@src/view/disbursement/Home.vue"),
      },
      {
        path: "invoice",
        name: "ViewInvoice",
        component: () => import("@src/view/disbursement/ViewInvoice.vue"),
      },
      {
        path: "detail",
        name: "detail",
        component: () => import("@src/view/disbursement/Detail.vue"),
      },
    ],
  }
]