
import { App } from 'vue';
import axios from "axios";
// @ts-ignore
import ErrorStackParser from 'error-stack-parser';
import sourceMap from 'source-map-js';
import { WEB_HOOK } from '../constant/constant';

// env环境变量
const IS_PRODUCTION = import.meta.env.VITE_WS_ENV === 'production';
// 错误处理函数
export function handleError(app: App) {
  // vue错误捕获
  app.config.errorHandler = async function(err: any, vm: any, info: any) {
    try {
      console.log("error:", err);
      if (IS_PRODUCTION) {
        const stackFrame = ErrorStackParser.parse(err)[0];
        // 获取对应的原始文件名、行和列信息，并上报
        const { fileName, columnNumber, lineNumber } = stackFrame || {};
        const pathArr = fileName?.split('/');
        const realFileName = pathArr?.[pathArr.length - 1];
        const res = await axios.get(`https://desty-front-indonesia.oss-ap-southeast-5.aliyuncs.com/source-map/menu-dashboard-source-map/${realFileName}.map`);
        const sourceData = res?.data || {};
        const consumer = await new sourceMap.SourceMapConsumer(sourceData);
        const result = consumer.originalPositionFor({
          line: Number(lineNumber),
          column: Number(columnNumber),
        });
      }
    } catch (error) {

    }
  };
  // js错误捕获
  window.onerror = function(message: any, source, lineno, colno, error) {
    if (message.includes('Script error')) return;
    // 机器人通知
  }
}
