import md5 from 'md5';
import { Tracker, TrackerConfig } from './Tracker.js';


export const createTracker = function (config) {
  const tracker = new Tracker(...config);

  let did = localStorage.getItem('sls_did');
  if (!did) {
    did = md5(`${navigator.userAgent}${+new Date()}${Math.random() * 999999999}`);
    localStorage.setItem('sls_did', did)
  }
  tracker.params = {
    location: window.location.href,
    did,
    agent: navigator.userAgent,
  };
  return tracker;
};

/**
 * 基础日志上报
 * @param {*} data
 */
export const pushLog = (data = {}, mandatory = false) => {
  console.log('执行push log')
  if (process.env.NODE_ENV == 'production' || mandatory) {
    console.log(`进来了`);
    const tracker = createTracker(TrackerConfig);
    tracker.push('timeStamp', +new Date())
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in data) {
      tracker.push(i, data[i]);
    }
    tracker.logger();
  }
};
