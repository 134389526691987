<template>
  <span class="px-2 text-xs py-0.5 rounded-sm" :class="calcCss">
    {{ calcText ? $t(calcText) : "" }}
  </span>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t, n } = useI18n();
const props = defineProps({
  text: {
    type: String,
  },
  extraClass: {
    type: String,
  },
  typeName: {
    type: String,
  },
  keyName: {
    type: String,
  },
});

const mapInfo: any = {
  order: {
    ORDER_CREATED: {
      name: "oms.orderInfo.created",
      class: "bg-sunflower-500  text-white",
    },
    ORDER_APPROVED: {
      name: "oms.orderInfo.newOrder",
      class: "bg-sunflower-500 text-white",
    },
    ORDER_PROCESSING: {
      name: "oms.orderInfo.preparing",
      class: "bg-green-500 text-white",
    },
    ORDER_SHIPPING: {
      name: "oms.orderInfo.ready",
      class: "bg-blue-500 text-white",
    },
    ORDER_COMPLETED: {
      name: "oms.orderInfo.completed",
      class: "bg-gray-300 text-white",
    },
    ORDER_CANCELLED: {
      name: "oms.orderInfo.cancel",
      class: "bg-gray-700 text-white",
    },
  },
  invoice: {
    true: {
      name: "Paid",
      class: "bg-green-500  text-white",
    },
    false: {
      name: "Unpaid",
      class: "bg-ele-originalTheme  text-white",
    },
  },
  disbursement: {
    CREATED: {
      name: "disbursement.home.statusSelect.created",
      class: "bg-ele-originalTheme  text-white",
    },
    SUCCESS: {
      name: "disbursement.home.statusSelect.completed",
      class: "bg-green-500  text-white",
    },
    PROCESSING: {
      name: "disbursement.home.statusSelect.processing",
      class: "bg-sunflower-500  text-white",
    },
    FAILED: {
      name: "disbursement.home.statusSelect.failed",
      class: "bg-red-500  text-white",
    },
  },
  discount: {
    FUTURE: {
      name: "discount.home.statusList.upcoming",
      class: "bg-blue-500 text-white py-1",
    },
    ACTIVE: {
      name: "discount.home.statusList.active",
      class: "bg-green-500 text-white py-1",
    },
    INACTIVE: {
      name: "discount.home.statusList.inactive",
      class: "bg-gray-400 text-white py-1",
    },
  },
};

const calcText = computed(() => {
  if (props.typeName && props.keyName) {
    try {
      return mapInfo[props.typeName][props.keyName].name;
    } catch (error) {
      console.error("请去检查type 和key 如果没有请去补充");
    }
  }
  return props.text || "";
});
const calcCss = computed(() => {
  if (props.typeName && props.keyName) {
    try {
      return mapInfo[props.typeName][props.keyName].class;
    } catch (error) {
      console.error("请去检查type 和key 如果没有请去补充");
    }
  }
  return props.extraClass;
});
</script>
