import i18 from "../lang/index";
const { t } = i18.global;
type serviceTypeMapKey = "PICK_UP" | "DINE_IN" | "DELIVERY";
const serviceTypeMap = {
  PICK_UP: t("common.serviceType.PICK_UP"),
  DINE_IN: t("common.serviceType.DINE_IN"),
  DELIVERY: t("common.serviceType.DELIVERY"),
  FULL_DINE_IN: t("common.serviceType.FULL_DINE_IN"),
};

export { serviceTypeMap, serviceTypeMapKey };

export const SERVICE_MAP = {
  PICK_UP: "common.serviceType.PICK_UP",
  DINE_IN: "common.serviceType.DINE_IN",
  DELIVERY: "common.serviceType.DELIVERY",
  FULL_DINE_IN: "common.serviceType.FULL_DINE_IN",
}

export const WEEK_ABBR_MAP = {
  SUNDAY: "Sun",
  MONDAY: "Mon",
  TUESDAY: "Tue",
  WEDNESDAY: "Wed",
  THURSDAY: "Thu",
  FRIDAY: "Fri",
  SATURDAY: "Sat",
}

export const WEEK_MAP = {
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
}

export const ORDER_GROUP_TYPE = {
  PACKAGE: 'PACKAGE',
  PACKAGE_BASE: 'PACKAGE_BASE',
  PACKAGE_OPTION: 'PACKAGE_OPTION',
  SINGLE: 'SINGLE',
  MODIFIER: 'MODIFIER',
}

export const PRODUCT_TYPE = {
  GOOD: 'products.main.type.single',
  PACKAGE_GOOD: 'products.main.type.bundling',
}