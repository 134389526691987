<template>
  <div class="m-times-picker">
    <VueDatePicker
      ref="dp"
      v-model="time"
      time-picker
      enable-seconds
      seconds-grid-increment="2"
      range
      placeholder="Select Time"
      @update:model-value="handleDate"
    >
      <template #action-extra>
        <div class="title">
          <span>Start Time</span>
          <span>End Time</span>
        </div>
      </template>
      <template #dp-input="{ value }">
        <div class="custom-view">
          <el-icon><Clock /></el-icon>
          <span class="times">
            <span class="time">{{
              splitTimes(value)[0] || "Start Times"
            }}</span>
            <span>to</span>
            <span class="time">{{ splitTimes(value)[1] || "End Times" }}</span>
          </span>
        </div>
      </template>

      <template #action-buttons>
        <el-button @click="closeMenu">Cancel</el-button>
        <el-button type="primary" @click="selectDate">OK</el-button>
      </template>
    </VueDatePicker>
  </div>
</template>

<script lang="ts" setup>
import { CircleClose, Clock } from "@element-plus/icons-vue";
const props = withDefaults(defineProps<{ modelValue: any }>(), {
  modelValue: [
    new Date(new Date(new Date().toLocaleDateString()).getTime()),
    new Date(
      new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    ),
  ],
});
const emits = defineEmits(["update:modelValue", "test"]);

const time = ref();

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue.length > 0) {
      time.value = [
        {
          hours: props.modelValue[0].getHours(),
          minutes: props.modelValue[0].getMinutes(),
          seconds: props.modelValue[0].getSeconds(),
        },
        {
          hours: props.modelValue[1].getHours(),
          minutes: props.modelValue[1].getMinutes(),
          seconds: props.modelValue[1].getSeconds(),
        },
      ];
    }
  },
  {
    immediate: true,
  }
);
const handleDate = (modelData: any) => {
  if (modelData) {
    let tempTime = JSON.parse(JSON.stringify(modelData));
    let temp = [
      new Date(
        0,
        0,
        0,
        tempTime[0].hours,
        tempTime[0].minutes,
        tempTime[0].seconds
      ),
      new Date(
        0,
        0,
        0,
        tempTime[1].hours,
        tempTime[1].minutes,
        tempTime[1].seconds
      ),
    ];
    time.value = modelData;
    emits("update:modelValue", temp);
  } else {
    emits("update:modelValue", []);
  }
};

const splitTimes = (val: any) => {
  return val.split("-");
};

const dp = ref();
const selectDate = () => dp.value.selectDate();
const closeMenu = () => dp.value.closeMenu();
</script>

<style lang="scss" scoped src="./index.scss"></style>
