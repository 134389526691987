import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "discount",
    component: BasicLayout,
    redirect: "/discount/home",
    meta: {
      permissionKey: "PC_Discount",
      navInfo: {
        icon: "menu-Tag",
        title: "common.sidebar.discount", // i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "discountHome",
        component: () => import("@src/view/discount/home/index.vue"),
      },
      {
        path: "detail",
        name: "discountDetail",
        component: () => import("@src/view/discount/detail/index.vue"),
      },
      {
        path: "addDiscount",
        name: "AddDiscount",
        component: () => import("@src/view/discount/add/AddDiscount.vue"),
      },
    ],
  }
]