<template>
    <span class="rounded-sm text-xs px-2 py-0.5" :class="map[props.status]?.class">
        {{$t( map[props.status]?.name)}}
    </span>
</template>
<script  setup lang="ts">
const props = defineProps({
    status: {
        type: String as any
    }
})

const map = {
    ITEM_GROUP_ACCEPTING: {
        name: "oms.orderInfo.created",
        class: "bg-sapphire-700  text-white",
    },
    ITEM_GROUP_PREPARING: {
        name: "oms.orderInfo.preparing",
        class: "bg-green-500 text-white",
    },
    ITEM_GROUP_SERVED: {
        name: "oms.orderInfo.served",
        class: "bg-blue-500 text-white",
    },
    ITEM_GROUP_COMPLETED: {
        name: "oms.orderInfo.done",
        class: "bg-gray-300 text-white",
    },
    ITEM_GROUP_CANCELED: {
        name: "oms.orderInfo.cancel",
        class: "bg-gray-700 text-white",
    },
    // ORDER_CREATED: {
    //     name: "oms.orderInfo.created",
    //     class: "bg-sapphire-700  text-white",
    // },
    // ORDER_APPROVED: {
    //     name: "oms.orderInfo.newOrder",
    //     class: "bg-sunflower-500 text-white",
    // },
    // ORDER_PROCESSING: {
    //     name: "oms.orderInfo.preparing",
    //     class: "bg-green-500 text-white",
    // },
    // ORDER_SHIPPING: {
    //     name: "oms.orderInfo.ready",
    //     class: "bg-blue-500 text-white",
    // },
    // ORDER_COITEM_GROUP_COMPLETEDMPLETED: {
    //     name: "oms.orderInfo.completed",
    //     class: "bg-gray-300 text-white",
    // },
    // ORDER_CANCELLED: {
    //     name: "oms.orderInfo.cancel",
    //     class: "bg-gray-700 text-white",
    // },
}
</script>