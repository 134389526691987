import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "account",
    component: BasicLayout,
    redirect: "/account/home",
    meta: {
      navInfo: {
        icon: "menu-User1",
        title: "common.sidebar.users",
      },
    },
    children: [
      {
        path: "home",
        name: "accountList",
        component: () => import("@src/view/account/Home.vue"),
        meta: {
          permissionKey: "PC_Users",
          navInfo: {
            icon: "menu-Disbursement",
            title: "common.sidebar.account", // i18n key
          },
        },
      },
      // {
      //   path: "roles",
      //   name: "rolesList",
      //   component: () => import("../view/account/Roles.vue"),
      //   meta: {
      //     permissionKey: "PC-Roles",
      //     navInfo: {
      //       icon: "menu-Disbursement",
      //       title: "common.sidebar.roles", // i18n key
      //     },
      //   },
      // },
    ],
  }
]