import { createI18n } from "vue-i18n"; //引入vue-i18n组件
import messages from "./autolanguage/formatJson";
const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
    },
  },
  "id-ID": {
    currency: {
      style: "currency",
      currency: "IDR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
    },
  },
};

// console.log(messages);

//注册i8n实例并引入语言文件
const i18 = createI18n({
  legacy: false, // composition API
  locale: "id-ID", //en-US
  // locale: "en-US", //en-US
  fallbackLocale: "id-ID",
  messages,
  numberFormats,
});
export default i18;
