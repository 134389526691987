import services from "./index";
import { sellerPreUrl, menuPermissionBaseUri } from "./config";

const getRoleDetail = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getRoleDetail`,
    method: "get",
    params: data,
  });
};
const updateRolePermission = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/updateRolePermission`,
    method: "post",
    data: data,
  });
};
const getPermissionTree = () => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getPermissionTree`,
    method: "get",
    // params: data,
  });
};
const getRoleList = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getRoleList`,
    method: "get",
    params: data,
  });
};

const getUsers = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getUserList`,
    method: "get",
    params: data,
  });
};
const addNewUser = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/user/seller/add`,
    method: "post",
    data: data,
  });
};
const updateUser = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/user/seller/update`,
    method: "post",
    data: data,
  });
};

const getUserGroupInfo = () => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getUserGroupInfo`,
    method: "get",
  });
};
// /menu/permission/getUserGroupInfo
const deleteUser = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/user/seller/delete`,
    method: "post",
    data,
  });
};
export {
  getUsers,
  getRoleList,
  getPermissionTree,
  updateRolePermission,
  getRoleDetail,
  addNewUser,
  updateUser,
  deleteUser,
  getUserGroupInfo,
};
