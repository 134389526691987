<template>
  <el-scrollbar
    height="calc(100vh - 48px - 32px)"
    class="w-56 flex-shrink-0 bg-white mb-40 pt-4 pb-4 rounded-sm"
  >
    <div>
      <el-menu
        mode="vertical"
        background-color="#fff"
        class="layout-menu"
        :default-active="defaultActive"
        text-color="#000"
        :router="true"
      >
        <template v-for="item in navList" :key="item.path">
          <el-menu-item v-if="!item.children" :index="item.path">
            <i class="menu-iconfont mr-4 font-normal" :class="item.icon"></i>
            <span>{{ $t(item.title) }}</span>
          </el-menu-item>
          <el-sub-menu :index="item.path" v-else>
            <template #title>
              <i class="menu-iconfont mr-4 font-normal" :class="item.icon"></i>
              <span>{{ $t(item.title) }}</span>
            </template>
            <el-menu-item
              v-for="(child, i) in item.children"
              :key="i"
              :index="child.path"
              >{{ $t(child.title) }}</el-menu-item
            >
          </el-sub-menu>
        </template>
      </el-menu>
    </div>

    <div
      class="h-12 fixed left-4 bg-white w-56 bottom-4 hover:bg-slate-200 cursor-pointer border border-solid border-white border-t-gray-200"
    >
      <div class="flex items-center h-12" @click="cancel">
        <i class="menu-iconfont menu-Union1 ml-5 text-base mr-2"></i>
        <span class="text-sm font-bold">{{ $t("common.sidebar.cancel") }}</span>
      </div>
    </div>
  </el-scrollbar>
</template>
<script lang="ts" setup>
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { hasPer } from "@src/until/permission";
import { logout } from "@http/login";
import { useStore } from "@src/store";
const store = useStore();
// sso需要区分
const isProduction = window.location.href.includes("dashboard.desty.menu"); //import.meta.env.VITE_WS_ENV === "production";
const isStaging = window.location.href.includes("pc-menuseller.desty.chat"); // is staging env
let envTypeVal = "chat";
if (isProduction) envTypeVal = "production";
else if (isStaging) envTypeVal = "staging";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const defaultActive = (function () {
  const pathArr = route.path.split("/");
  if (pathArr[2] == "home") {
    return `/${pathArr[1]}`;
  }
  return route.path;
})();
const navList: any = computed(() => {
  let current: any = router.getRoutes();
  // 筛选有效路由
  current = current.filter((item: any) => {
    return item.path.split("/").length == 2 && item?.meta?.navInfo;
  });
  // 在有效路由下，筛选有权限的路由
  current = current.filter((item: any) => {
    return (
      !item.meta?.permissionKey ||
      (item.meta?.permissionKey && hasPer(item.meta?.permissionKey))
    );
  });
  current = current.map((item: any) => {
    let res: any = {};
    res = { ...item.meta.navInfo, path: item.path };
    // 处理没实现的页面（有路由，没实现）
    const temlChildren: any = item.children?.filter(
      (item: any) => item?.meta?.navInfo
    );
    // 权限判断是否显示
    const showChildren = temlChildren.filter((item: any) => {
      return (
        !item.meta?.permissionKey ||
        (item.meta?.permissionKey && hasPer(item.meta?.permissionKey))
      );
    });
    // 权限过滤后如果子路由全部没有权限，打个标
    if (temlChildren.length !== showChildren.length && !showChildren.length) {
      res.noShow = true;
    }
    // 处理显示路由
    if (showChildren?.length > 0) {
      res.children = showChildren.map((child: any) => {
        return { ...child.meta.navInfo, path: `${item.path}/${child.path}` };
      });
    }

    return res;
  });
  // 子路由全部没有权限就不显示一级导航
  current = current.filter((item: any) => !item.noShow);
  return current;
});

const clearData = async () => {
  window.localStorage.clear();
  router.go(0);
};

const cancel = () => {
  logout()
      .catch((err) => {
        console.log("other role logout", err);
      })
      .finally(() => {
        clearData();
      });
};
</script>

<style lang="scss">
.layout-menu {
  border-right: 0;
  .el-menu-item,
  .el-sub-menu {
    font-weight: bold;
  }

  .el-sub-menu .el-menu-item {
    font-weight: normal;
  }
  .el-menu-item.is-active {
    background: #f3f4f6;
    border-right: 3px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
  }
}
</style>
