const mock = "https://foryapi.desty.chat/mock"
const sellerPreUrl = "menu-api";
const menuPermissionBaseUri = "menu-permission-api";
const menuUser = "menu-user";
const microService = "menu-order-cart"
const menuPos = "menu-pos"

const menuProduct = "menu-product"
const menuProd = "menu-product"

export { sellerPreUrl, menuPermissionBaseUri, menuUser, microService, menuPos, mock, menuProduct, menuProd };
