import { createApp } from "vue";
import App from "./App.vue";
import router, { setupRouter } from "./router";
import i18 from "./lang";
import { handleError } from "./until/error_tracker";
import { createPinia } from "pinia";
import "./assets/tillwindreset.css";
import "./assets/iconfont/iconfont.css";
import "./assets/eleRest.css";
import "./assets/common.css";
import "element-plus/es/components/message/style/css";

import MUI from "./components";

const pinia = createPinia();
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import "./until/formatDate";

import echarts from "./until/echarts";

//@ts-ignore
import { limitImgUrl } from './utils/ossLimit';

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
// 错误捕获
handleError(app);

app.config.globalProperties.$echarts = echarts;

// 定义全局主题颜色
app.config.globalProperties.$primaryColor = "#ec2a00";

app.config.globalProperties.$ossLimit = limitImgUrl;

app.use(i18);
app.use(pinia);

app.component("VueDatePicker", VueDatePicker);

// app.use(router);
// 挂载路由
setupRouter(app);

// 路由准备就绪后挂载APP实例
router.isReady();

import permission from "./plugins/permission";
// import fullstory from "./plugins/fullstory";
// import amplitude from "./plugins/amplitude";
app.use(permission);
// app.use(fullstory);
// app.use(amplitude);
// 注册自定义组件
app.use(MUI);

app.mount("#app");
