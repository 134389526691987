import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "setting",
    component: BasicLayout,
    redirect: "/setting/home",
    meta: {
      navInfo: {
        icon: "menu-Settings",
        title: "common.sidebar.setting", // i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "settingHome",
        component: () => import("@src/view/setting/Home.vue"),
        meta: {
          permissionKey: "PC_Setting",
          navInfo: {
            icon: "menu-Disbursement",
            title: "common.sidebar.outlets", // i18n key
          },
        },
      },
      {
        path: "integrasi/:type",
        name: "Integrasi",
        component: () => import("@src/view/setting/Integrasi.vue"),
        meta: {
          permissionKey: "CanViewIntegrasi",
          navInfo: {
            title: "common.sidebar.market", // i18n key
  
          },
        },
      },
      // {
      //   path: "integrasi/:type",
      //   name: "Integrasi",
      //   component: Integrasi,
      //   meta: {
      //     navInfo: {
      //       // icon: "menu-Disbursement",
      //       title: "integrasi", // i18n key
      //     },
      //   },
      // },
  
  
      // {
      //   path: "account",
      //   name: "account",
      //   component: Account,
      //   meta: {
      //     navInfo: {
      //       icon: "menu-Disbursement",
      //       title: "common.sidebar.account", // i18n key
      //     },
      //   },
      // },
      // {
      //   path: "basicSet",
      //   name: "basicSet",
      //   component: BasicSet,
      //   meta: {
      //     navInfo: {
      //       icon: "menu-Disbursement",
      //       title: "common.sidebar.basicSet", // i18n key
      //     },
      //   },
      // },
      // {
      //   path: "materials",
      //   name: "materials",
      //   component: Materials,
      //   meta: {
      //     navInfo: {
      //       icon: "menu-Disbursement",
      //       title: "common.sidebar.materials", // i18n key
      //     },
      //   },
      // },
      // {
      //   path: "checkoutSet",
      //   name: "checkoutSet",
      //   component: CheckoutSet,
      //   meta: {
      //     navInfo: {
      //       icon: "menu-Disbursement",
      //       title: "common.sidebar.checkoutSet", // i18n key
      //     },
      //   },
      // },
    ],
  }
]