import axios from "axios";
import { isRef, reactive, Ref } from "vue";
import { ElMessage } from "element-plus";
// @ts-ignore
import i18 from "../lang/index";
import router from "../router";
import { useStore } from "../store/index";
// @ts-ignore
import { pushLog } from '../until/pushLog.js'
import { IS_PRODUTION, WEB_HOOK } from "@/constant/constant";

const service = axios.create({ withCredentials: true });
service.interceptors.request.use((config: any) => {
  const store = useStore();
  const token = store.token || localStorage.getItem("token");
  if (token) {
    // 不同服务端，传不同参。。。凸凸，鄙视服务端
    // if (config.url.startsWith(`/${sellerPreUrl}`)) {
    //   config.headers.Authentication = `Bearer ${token}`;
    // }
    // if (config.url.startsWith(`/${menuPermissionBaseUri}`)) {
    //   config.headers.token = `Bearer ${token}`;
    // }

    // 狗屁服务端。。。。上面注释掉的逻辑是不同key，这边的逻辑是不同值。。。垃圾
    if (token.includes("Bearer")) {
      config.headers.Authentication = token;
    } else {
      config.headers.Authentication = `Bearer ${token}`;
    }
    if (!store.isSysAdmin) {
      config.headers.productStoreId = store.userStoreIds
    }
    //
  }
  // 临时代码
  // config.headers.productStoreId = store.userStoreIds.split(",")[0];
  // if (store.groupId) {
  //   config.headers.groupId = store.groupId;
  // }
  // config.headers["local-agent"] = 'DestyMenuSeller';
  config.headers["Language"] = store.language;
  return config;
});
//
service.interceptors.response.use(
  (response: any) => {
    const store = useStore();
    if (response.headers["new-token"]) {
      const newToken = response.headers["new-token"];
      store.token = newToken;
      localStorage.setItem("token", newToken);
    }

    //  bolb 文件直接返回不用格式化
    if (response.config.responseType === "blob") {
      return response;
    }
    //  严谨点应该是  json数据才能走到格式化.
    let res: any = {
      originInfo: response,
    };
    const reqUrl = response.config.url;
    // 拍平数据统一 :   1 jack后台返回格式
    if (
      reqUrl.startsWith("/menu-user") ||
      reqUrl.startsWith("/menu-api/seller") ||
      reqUrl.startsWith("/menu-permission-api") ||
      reqUrl.startsWith("/menu-pos")
    ) {
      res = {
        ...res,
        ...response.data,
      };
      if (res.code) {
        res.code = res.code.toString();
      }
      if (res?.code !== "200" && res?.code !== 200) {
        ElMessage({
          showClose: true,
          message:
            res.message ||
            res.msg ||
            "应该抛出后台的错误信息,如果看到此消息,请去完善",
          type: "error",
        });
        res.error = true;
      } else {
        res.success = true;
      }
      return res;
    }
    // console.warn("看到此消息,请去全局数据拦截 完善此类请求的拍平统一");
    return res.originInfo;
  },
  (error) => {
    const errorData = error.response;
    pushLog({
      key: 'pc-seller response error',
      code: errorData?.data?.code,
      status: errorData?.status,
      message: errorData?.data?.message,
      url: errorData?.config.url,
      data: errorData?.config.data,
      token: errorData?.config.headers?.Authentication
    });
    const store = useStore();
    const code = error.response.status;
    const noClear = localStorage.getItem("noClear");
    if (code == 401 || code == 403) {
      if (noClear) {
        console.log("进入调试区 不返回");
        return;
      }
      ElMessage({
        showClose: true,
        message: i18.global.t("login.error.reLogin"),
        type: "error",
        grouping: true,
      });
      localStorage.clear();
      router.push("/login");
    }
    ElMessage({
      showClose: true,
      message: i18.global.t("common.serviceErr"),
      type: "error",
      grouping: true,
    });
    error.success = false;
    return Promise.resolve(error);
  }
);
export default service;
interface useAxiosStateOption {
  success?: Function;
  error?: Function;
  end?: any;
  params?: Ref | Object;
  immediate?: boolean;
  defaultData?: any;
  loadingChange?: Function;
}
interface resAxiosState {
  loading: boolean;
  errMsg: any;
  data: any;
  againRun: any;
}
export function useAxiosState(fn: Function, option?: useAxiosStateOption) {
  const state = reactive<resAxiosState>({
    loading: false,
    errMsg: null,
    data: option?.defaultData,
    againRun: (params?: any) => {
      // 屏蔽处理点击函数的Event
      if (params instanceof Event) {
        params = undefined;
      }
      // 无参默认值取响应式参数
      params = params === undefined ? option?.params : params;
      // 扒掉Ref
      params = isRef(params) ? params.value : params;
      // 执行
      exec(fn, params);
    },
  });
  function exec(fn: Function, params: any) {
    let { success, error, end, loadingChange } = option || {};
    state.loading = true;
    loadingChange && loadingChange(state.loading);
    fn(params)
      .then((res: any) => {
        state.data = res.data;
        // 参数1 数据, 2.源数据(全量)
        success && success(state.data, res);
      })
      .catch((e: any) => {
        state.errMsg = e;
        error && error(e);
      })
      .finally(() => {
        state.loading = false;
        loadingChange && loadingChange(state.loading);
        end && end();
      });
  }
  if (option === undefined || option?.immediate) {
    exec(fn, isRef(option?.params) ? option?.params?.value : option?.params);
  }
  return state;
}


