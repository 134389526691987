import { App } from "vue";
import { hasPer, hasStore } from "../until/permission";

// 自定义类型申明
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $hasPer: Function
    $hasSrore: Function
  }
}

export default {
  install(app: App): void {
    app.config.globalProperties.$hasPer = hasPer;
    app.config.globalProperties.$hasSrore = hasStore;
  },
};
