export const routes = [
  {
    path: "analysis",
    component: () => import("@src/view/analysis/index.vue"),
    redirect: "/analysis/home",
    meta: {
      permissionKey: "PC_Analysis",
      navInfo: {
        icon: "menu-Analytics",
        title: "common.sidebar.analysis", // i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "analysisHome",
        component: () => import("@src/view/analysis/Home.vue"),
      },
      {
        path: "salelist",
        name: "SaleList",
        component: () => import("@src/view/analysis/SaleList.vue"),
      },
    ],
  }
]
