import { defineStore } from "pinia";
export const useStore = defineStore("main", {
  state: () => {
    return {
      token: "",
      language: "id-ID",
      userId: "",
      // storeInfo: {
      //   id: "",
      //   role: "",
      // },
      groupId: "",
      permissionLoaded: false,
      permission: {},
      userStores: [],
      // roleId: "",
      dataLevel: "",
      destyTransRef: null,
      publicUserId: '', // 主站userId，进行公共退出使用
      storePosMap: {}
    };
  },
  persist: {
    key: "pc-menu-seller",
  },
  getters: {
    userStoreHash(state) {
      const _hash = {};
      state.userStores.forEach(({ id, name }) => {
        _hash[id] = name;
      });
      return _hash;
    },
    userStoreGroupHash(state) {
      const _hash = {};
      state.userStores.forEach(({ id, groupId }) => {
        _hash[id] = groupId;
      });
      return _hash;
    },
    isSysAdmin(state) {
      return state.dataLevel === "0";
    },
    isCashier(state) {
      return state.dataLevel === "2";
    },
    // 临时方案,  后台不支持null参数自己解析店铺 非要前端遍历加上.
    userStoreIds(state): any {
      const _ids: Array<number> = [];
      state.userStores.forEach(({ id }) => {
        _ids.push(id);
      });
      return this.isSysAdmin ? null : _ids.join();
    },
  },
  actions: {
    // setStoreInfo(payload: any) {
    //   this.storeInfo = payload;
    // },
    setLanguage(payload: any) {
      this.language = payload;
    },
    setPermission(data: any) {
      this.permission = data;
    },
    setGroupId(data: any) {
      this.groupId = data;
    },
    setUserStores( data: any) {
      // todo: 此处会导致控制台一个警告
      this.userStores = data;
    },
    // setRoleId(data: any) {
    //   this.roleId = data;
    // },
    setDataLevel(data: string) {
      this.dataLevel = data;
    },
    setUserId(data: string) {
      this.userId = data;
    },
    setPublicUserId(data: string) {
      this.publicUserId = data;
    },
    setStorePosMap(data: any) {
      this.storePosMap = data;
    }
  },
});
