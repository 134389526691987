import language from "./language.json";
function format(obj: any): any {
  for (const k in obj) {
    const item = obj[k];
    if (typeof item === "object") {
      format(item);
    } else {
      let temp;
      try {
        temp = JSON.parse(item);
        if (Array.isArray(temp)) {
          obj[k] = temp;
        }
      } catch (error) {
        // console.log("转array报错");
      }
      // --------------------
      try {
        if (/\s*function/.test(item)) {
          // console.log("抓到函数了");
          // console.log(item);
          temp = new Function(`return   ${item}`);
          obj[k] = temp();
        }
      } catch (error) {
        // console.log("转 function 报错");
      }
    }
  }
  // return result;
}
format(language);
export default language;
