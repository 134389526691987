import { App } from "vue";
import Mheader from "./MHeader";
import MOrderDetails from "./MOrderDetails";
import MPager from "./MPager";
import MTimesPicker from "./MTimesPicker";

const components = [Mheader, MOrderDetails, MPager, MTimesPicker];

export default {
  install(app: App) {
    components.map((item) => app.use(item));
  },
};
