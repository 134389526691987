import { Router } from 'vue-router';
import { useStore } from "@src/store";
import { hasPer } from "@/until/permission";

export function createRouterGuards(router: Router) {
  router.beforeEach(async (to: any, from, next) => {
    const store = useStore();
    const token = window.localStorage.getItem("token");
  
    // login 直接放行
    if (to.path.startsWith("/login")) {
      if (token) {
        next("/oms/home");
        return;
      }
      next();
      return;
    }
    // 无token 定向去登录
    if (!token) {
      // console.log(`无token`);
      // console.log(`to`, to);
      // console.log(`from`, from.fullPath);
      // console.log(`from`, from.query);
      next({
        path: "/login/home",
        query: {
          callback_url: encodeURIComponent(window.location.href),
        },
      });
      return;
    }
  
    // 进入没有权限的页面应该跳去没权限的页面，暂时没有，跳去的登录
    if (to.meta?.permissionKey && !hasPer(to.meta?.permissionKey)) {
      localStorage.clear();
      console.log(`无权限`);
      next("/login/home");
      return;
    }
  
    // 有token 抓取用户信息 且 摇树生成一次权限路由表
    // if (!store.state.storeInfo?.id) {
    // const res: any = await getStoreInfo();
    // if (res.code.toString() != "200") {
    //   localStorage.clear();
    //   next("/login");
    // }
    // store.commit("setStoreInfo", res.data);
    // const newR = routerTreeShake(routes, store.state.storeInfo.role);
    // newR.forEach((element: any) => {
    //   router.addRoute(element);
    // });
    // next({ ...to });
    // return;
    // }
    next();
  });
}