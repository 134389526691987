import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "customer-list",
    component: BasicLayout,
    redirect: "/customer-list/home",
    meta: {
      permissionKey: "PC_CustomerList",
      navInfo: {
        icon: "menu-users",
        title: "common.sidebar.customer", //i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "customerListHome",
        component: () => import("@src/view/customer/home/index.vue"),
      },
      {
        path: "detail-customer",
        name: "detailCustomer",
        component: () => import("@src/view/customer/detail/index.vue"),
      },
    ],
  }
]