var id = {
  name: "id",
  el: {
    colorpicker: {
      confirm: "Pilih",
      clear: "Kosongkan",
    },
    datepicker: {
      now: "Sekarang",
      today: "Hari ini",
      cancel: "Batal",
      clear: "Kosongkan",
      confirm: "Ya",
      selectDate: "Pilih tanggal",
      selectTime: "Pilih waktu",
      startDate: "Tanggal Mulai",
      startTime: "Waktu Mulai",
      endDate: "Tanggal Selesai",
      endTime: "Waktu Selesai",
      prevYear: "Tahun Sebelumnya",
      nextYear: "Tahun Selanjutnya",
      prevMonth: "Bulan Sebelumnya",
      nextMonth: "Bulan Selanjutnya",
      year: `  `,
      month1: "Januari",
      month2: "Februari",
      month3: "Maret",
      month4: "April",
      month5: "Mei",
      month6: "Juni",
      month7: "Juli",
      month8: "Agustus",
      month9: "September",
      month10: "Oktober",
      month11: "November",
      month12: "Desember",
      week: "Minggu",
      weeks: {
        sun: "Min",
        mon: "Sen",
        tue: "Sel",
        wed: "Rab",
        thu: "Kam",
        fri: "Jum",
        sat: "Sab",
      },
      months: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "Mei",
        jun: "Jun",
        jul: "Jul",
        aug: "Agu",
        sep: "Sep",
        oct: "Okt",
        nov: "Nov",
        dec: "Des",
      },
    },
    select: {
      loading: "Memuat",
      noMatch: "Tidak ada data yg cocok",
      noData: "Tidak ada data",
      placeholder: "Pilih",
    },
    cascader: {
      noMatch: "Tidak ada data yg cocok",
      loading: "Memuat",
      placeholder: "Pilih",
      noData: "Tidak ada data",
    },
    pagination: {
      goto: "Pergi ke",
      pagesize: "/halaman",
      total: "Total {total}",
      pageClassifier: "",
      deprecationWarning:
        "Penggunaan yang tidak akan digunakan lagi terdeteksi, silakan lihat dokumentasi el-pagination untuk lebih jelasnya",
    },
    messagebox: {
      title: "Pesan",
      confirm: "Ya",
      cancel: "Batal",
      error: "Masukan ilegal",
    },
    upload: {
      deleteTip: "Tekan hapus untuk melanjutkan",
      delete: "Hapus",
      preview: "Pratinjau",
      continue: "Lanjutkan",
    },
    table: {
      emptyText: "Tidak ada data",
      confirmFilter: "Konfirmasi",
      resetFilter: "Atur ulang",
      clearFilter: "Semua",
      sumText: "Jumlah",
    },
    tree: {
      emptyText: "Tidak ada data",
    },
    transfer: {
      noMatch: "Tidak ada data yg cocok",
      noData: "Tidak ada data",
      titles: ["Daftar 1", "Daftar 2"],
      filterPlaceholder: "Masukan kata kunci",
      noCheckedFormat: "{total} item",
      hasCheckedFormat: "{checked}/{total} terpilih",
    },
    image: {
      error: "GAGAL",
    },
    pageHeader: {
      title: "Kembali",
    },
    popconfirm: {
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    },
  },
};
export default id;
