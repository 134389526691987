import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "oms",
    component: BasicLayout,
    redirect: "/oms/home",
    meta: {
      permissionKey: "PC_OMS",
      navInfo: {
        icon: "menu-OMS",
        title: "common.sidebar.oms", //i18n key
      },
    },
    children: [
      {
        path: "home",
        name: "OMSHome",
        component: () => import("@src/view/oms/index.vue"),
      },
    ],
  }
]
