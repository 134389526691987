import BasicLayout from "@src/layout/BasicLayout.vue"

export const routes = [
  {
    path: "products",
    component: BasicLayout,
    redirect: "/products/main",
    meta: {
      permissionKey: "PC_PMS",
      navInfo: {
        icon: "menu-products",
        title: "common.sidebar.products.title", // i18n key
      },
    },
    children: [
      {
        path: "main",
        name: "Main Product",
        component: () => import("@src/view/products/main/list/index.vue"),
        meta: {
          navInfo: {
            icon: "menu-Disbursement",
            title: "common.sidebar.products.main", // i18n key
          },
        },
      },
      {
        path: "add",
        name: "Add New Product",
        component: () => import("@src/view/products/main/add/index.vue"),
        
      },
      {
        path: "edit",
        name: "Edit Product",
        component: () => import("@src/view/products/main/add/index.vue"),
      },
      {
        path: "modifiers",
        name: "Modifiers",
        component: () => import("@src/view/products/modifiers/index.vue"),
        meta: {
          navInfo: {
            icon: "menu-Disbursement",
            title: "common.sidebar.products.modifiers", // i18n key
          },
        },
      },
    ],
  }
];
