
import { computed } from 'vue';
import { useI18n } from "vue-i18n";


export function useProductPrice(product) {
  const { t } = useI18n();
  const quantity = computed(() => product.value.quantity);
  const singleTotal = computed(() => product.value.baseSingleTotal);
  const finalTotal = computed(() => product.value.finalSingleTotal);

  const promoList = computed(() => {
    if (!product.value) return [];
    let qt = product.value.quantity;
    const list = product.value.priceDiscount?.map(it => {
      const { modifyAmount, discountQuantity } = it;
      qt -= discountQuantity;
      // count,promoPrice,normalPrice
      const final = singleTotal.value + modifyAmount;
      const normal = singleTotal.value;
      return {
        count: discountQuantity,
        price: final,
        normalPrice: normal
      }
    }) || [];
    if (qt > 0) {
      list.push({
        count: qt,
        price: singleTotal.value,
        normalPrice: singleTotal.value,
        isNormal: true
      })
    }
    return list;
  });
  const delPrice = computed(() => {
    const total = promoList.value.reduce((acc, cur) => {
      const { count, normalPrice } = cur;
      return acc + normalPrice * count;
    }, 0)
    return total;
  });
  const price = computed(() => {
    const total = promoList.value.reduce((acc, cur) => {
      const { count, price: p, normalPrice } = cur;
      return acc + p * count;
    }, 0)
    return total;
  });
  const promoTile = computed(() => {
    const v1 = product.value?.priceDiscount?.reduce((pre, it) => pre + it.discountQuantity, 0);
    const v2 = quantity.value - v1;
    return t('oms.seeAllPromo', { v1, v2 });
  });
  const showPromo = computed(() => {
    const { priceDiscount = [] } = product.value || {};
    if (!priceDiscount || priceDiscount?.length === 0) return false;
    if (priceDiscount?.length === 1) {
      const discount = priceDiscount[0];
      if (discount.discountQuantity === quantity.value) return false;
      if (discount.discountQuantity === 0) return false;
    }
    return true;
  })



  return { promoList, delPrice, price, promoTile, showPromo }
}