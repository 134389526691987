import services from "./index";
import { sellerPreUrl, menuPermissionBaseUri, menuUser } from "./config";
interface SendCodeParams {
  address: string;
  expire?: boolean;
  language?: "id";
  type?: string;
  situation?: string;
  source?: string;
  loginFrom?: number;
}
interface Verify {
  address: string;
  captcha: string;
}
// const sendVerificationCode = (params: SendCodeParams) => {
//   params = {
//     source: "Menu",
//     loginFrom: 0,
//     expire: true,
//     language: "id",
//     type: "WHATSAPP",
//     situation: "LOGIN",
//     ...params,
//   };
//   return services.post("/api/captcha", params);
// };
const sendVerificationCode = (params: SendCodeParams) => {
  params = {
    source: "Menu",
    loginFrom: 0,
    expire: true,
    language: "id",
    type: "WHATSAPP",
    situation: "LOGIN",
    ...params,
  };
  return services.post(`/${menuUser}/menu/user/captcha`, params);
};
// const checkVerify = (params: Verify) => {
//   const temp = {
//     source: "Menu",
//     type: "WHATSAPP",
//     situation: "LOGIN",
//   };
//   return services.post("/api/captcha/verify", { ...temp, ...params });
// };
const checkVerify = (params: Verify) => {
  const temp = {
    source: "Menu",
    type: "WHATSAPP",
    situation: "LOGIN",
  };
  return services.post(`/${menuUser}/menu/user/login`, {
    ...temp,
    ...params,
  }, {
    headers: { 'client-type': 'pc' },
  });
};

const getStoreInfo = () => {
  return services.get(`/${sellerPreUrl}/seller/store`);
};

// const getStoreList = () => {
//   return services.get(`/${sellerPreUrl}/seller/storeList`);
// };

const getStoreInfoByGroupId = (groupId?: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getStoreInfoByGroupId?groupId=${groupId}`,
    method: "get",
  });
};
const getStoreList = (params?: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getStoreList`,
    method: "get",
    params,
  });
};
const setStoreIdToBeManaged = (params: any) => {
  return services.post(
    `/${sellerPreUrl}/seller/setStoreIdToBeManaged`,
    {},
    {
      headers: { storeIdToBeManaged: params },
    }
  );
};
const getUserPermission = () => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getUserPermission`,
    method: "get",
    // params: data,
  });
};
const getUserStoreList = (data: any) => {
  return services({
    url: `/${menuPermissionBaseUri}/menu/permission/getStoreList`,
    method: "get",
    params: data,
  });
};
// 拿到列表后调用
//
// POST
// 参数： storeIdToBeManaged=XXX

const logout = () => {
  return services.get(`/${menuUser}/menu/user/logout`, {
    headers: { 'client-type': 'pc' },
  });
};

export {
  getStoreInfo,
  sendVerificationCode,
  checkVerify,
  getStoreList,
  setStoreIdToBeManaged,
  getUserPermission,
  logout,
  getUserStoreList, getStoreInfoByGroupId
};
