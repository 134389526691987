import { useStore } from "../store";
import { getUserPermission, getStoreList } from "../http/login";
import { getPermissionTree } from "../http/users";
import { batchQueryStorePosInfo } from "../http/products"
import service from "@/http";
import { sellerPreUrl } from "@/http/config";

export const hasPer = (keys?: string | Array<string>): boolean => {
  if (!keys) {
    return true;
  }
  let _keys: Array<string> = [];
  if (keys.constructor === String) {
    _keys = keys.split(",");
  }
  if (keys.constructor === Array) {
    _keys = keys;
  }
  const store = useStore();
  const authorized = store.permission || {};
  if (_keys.length === 1) {
    return !!authorized[_keys[0]];
  }
  const verifys = _keys.map((key) => !!authorized[key]);
  return verifys.filter((it) => !it).length === 0;
};

export const hasStore = (...keys: [string]): boolean => {
  const store = useStore();
  const stores = store.userStoreHash || {};
  //   const verifys = keys.map(key => !!authorized[key]);
  //   return verifys.filter(it => !it).length === 0;
  return true;
};
// 只更新用户店铺信息
export const initUserStores = async () => {
  const store = useStore();
  const res: any = await getStoreList({ pageSize: 10000 });
  const { success, data } = res;
  if (!success || !data) {
    return false;
  }
  const posStoreMap = store.userStores.map((it: any) => ({
    [it.id]: it.postType
  }))
  const userStores: Array<any> = [];
  (data.data || []).forEach((storeItem: any) => {
    const { productStoreId, productStoreName, storeId, storeName, id, name, groupId, groupName } =
      storeItem;
    // 服务端，太水了，数据没抹平
    userStores.push({
      id: (productStoreId || storeId || id) - 0,
      name: productStoreName || storeName || name,
      groupId: groupId,
      groupName: groupName,
      posType: posStoreMap[id]
    });
  });
  store.setUserStores(userStores);
  return true;
};
export function initPosType() {
  const store = useStore();
  const userStores = store.userStores;
  const ids = userStores.map((it: any) => it.id);
  let tempPosType = {}
  let tempIdsCollect = []
  if(ids && ids.length > 0) {
    let index = 0
    while (index < ids.length) {
      tempIdsCollect.push(ids.slice(index, index += 200))
    }
  }
  tempIdsCollect.forEach(async group => {
    let params = {
      params: { productStoreIdList: group }
    }
    let res = await batchQueryStorePosInfo(params)
    Object.assign(tempPosType, res.data);
  })

  return store.setStorePosMap(tempPosType);
  // return service.post(`/${sellerPreUrl}/seller/batchQueryStorePosInfo`, {
  //   params: { productStoreIdList: ids }
  // }).then(res => {
  //   store.setStorePosMap(res.data);
  // })
}
export const init = async () => {
  const store = useStore();
  const res: any = await getUserPermission();
  const { success, error, data, message } = res;
  // sso登录过来未激活拦截
  if (!success || !data?.isActivate) {
    return { success, error, data, message };
  }
  const groupId = data.groupId;
  const userId = data.userId;
  const publicUserId = data.publicUserId;
  const dataLevel = data.dataLevel + "";
  let userStores: Array<any> = [];
  const permission = {};
  // 获取绑定的店铺，以及权限
  (data.storeInfo || data.storeInfoList || []).forEach((storeData: any) => {
    const { id, name, permissionList, postType } = storeData;
    userStores.push({ id: id - 0, name, postType });
    permissionList &&
      permissionList.forEach((permissionItem: any) => {
        const { code, description } = permissionItem;
        permission[code] = description;
      });
  });
  // 系统管理员内部实现了setUserStores，所以这里需要前置处理
  store.setUserStores(userStores);
  // 系统管理员，获取所有权限
  if (dataLevel === "0") {
    const sysAdminData: Array<any> = await Promise.all([
      initUserStores(),
      getPermissionTree(),
    ]);
    const [isInitUserSrote, allPermission] = sysAdminData;
    // const allPermission: any = await getPermissionTree();
    if (allPermission.success) {
      allPermission.data.forEach((permissionItem: any) => {
        const { code, description, children } = permissionItem;
        permission[code] = description;
        children &&
          children.forEach((permissionItem: any) => {
            const { code, description } = permissionItem;
            permission[code] = description;
          });
      });
    } else {
      return { success: false, message: "error" };
    }
    if (!isInitUserSrote) {
      return { success: false, message: "error" };
    }
  }
  store.setPermission(permission);
  store.setGroupId(groupId);
  store.setDataLevel(dataLevel);
  store.setUserId(userId);
  store.setPublicUserId(publicUserId);
  await initPosType();
  return { success, error, data, message };
};
