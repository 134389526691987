import i18n from '../lang/index'

// 时间戳转换 
// todo：完善第二个参数 format 转换
function formatTimeStamp(val: number, format: string) {
  let date: Date = new Date(val)
  let year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  month < 10 ? `0${month}` : month
  return `${month} Mar ${year}`
}

function formatBeforeDate(val: number) {
  let now = new Date().getTime();
  let before = new Date(now - val);
  let day = Math.trunc(before / (1000 * 60 * 60 * 24));
  return  day > 0 ? `${day} days ago` : "today"
}

function formatDate(val: Date, Separate?: string, reverse?: boolean) {
  if (!val) {
    return "";
  }
  const year = val.getFullYear();
  let month: number | string = val.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: number | string = val.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  const dateArr = [year, month, day];
  let dateString = "";
  if (reverse) {
    dateString = dateArr.reverse().join(Separate ? Separate : ".");
  } else {
    dateString = dateArr.join(Separate ? Separate : ".");
  }

  return dateString;
}

function formatDateToArr(val: Date) {
  if (!val) {
    return { date: [], time: [] };
  }
  const year = val.getFullYear();
  let month: number | string = val.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: number | string = val.getDate();
  if (day < 10) {
    day = `0${day}`;
  }

  //
  let hour: number | string = val.getHours();
  if (hour < 10) {
    hour = `0${hour}`;
  }

  let minute: number | string = val.getMinutes();
  if (minute < 10) {
    minute = `0${minute}`;
  }
  let second: number | string = val.getSeconds();
  if (second < 10) {
    second = `0${second}`;
  }
  return { date: [year, month, day], time: [hour, minute, second] };
}

function formatDateToDDMMYYHHMMSS(dateNumber: number) {
  let current = formatDateToArr(new Date(dateNumber));
  let { date, time } = current;
  return `${date.reverse().join("/")}   ${time.join(":")} `;
}

function getRange(dateArr: any[] | null) {
  if (!dateArr) {
    return null;
  }
  let start = Number(dateArr[0].setHours(0, 0, 0, 0));
  let end = Number(dateArr[1].setHours(0, 0, 0, 0));
  end = end + 24 * 60 * 60 * 1000 - 1;
  return {
    start: start,
    end: end,
  };
}

function fillZero(num:number) { return num < 10 ? `0${num}` : num }
Date.prototype.Format = function (fmt:any) { //author: meizz 
  const EN_M_MAP = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const ID_M_MAP = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des']
  const MAP = i18n.global.locale === 'id-ID' ? ID_M_MAP : EN_M_MAP;
  const FORMAT = {
    "yyyy": this.getFullYear(),
    "MM": fillZero(this.getMonth() + 1),
    "MEng": MAP[Number(fillZero(this.getMonth()))], // 数组下标从0开始的，不用+1
    "dd": fillZero(this.getDate()),
    "HH": fillZero(this.getHours()),
    "mm": fillZero(this.getMinutes()),
    "ss": fillZero(this.getSeconds()),
    "qq": Math.floor((this.getMonth() + 3) / 3),
    "S": this.getMilliseconds(),
  };
  let res = fmt
  for (var k in FORMAT) if (new RegExp(k).test(fmt)) res = res.replace(k, FORMAT[k])
  return res;
}
export { formatTimeStamp, formatBeforeDate, formatDateToArr, formatDate, formatDateToDDMMYYHHMMSS, getRange };
