<template>
  <el-config-provider :locale="elLang">
    <router-view></router-view>
  </el-config-provider>
</template>
<script lang="ts" setup>
import id from "./ele18/id";
import en from "./ele18/en";
// @ts-ignore
import { useStore } from "./store/index";
import * as echarts from "echarts";
import { provide } from "vue";
provide("echarts", echarts);
const store = useStore()

const elLang = computed(() => store.$state.language === 'id-ID' ? id: en)
</script>
