<template>
  <el-scrollbar max-height="640px">
    <div class="relative mb-15">
      <div
        class="py-2 pb-0 px-4 mb-4"
        v-if="
          detail.disbursementStatus === 'REJECTED' &&
          !detail.disbursementPaymentStatus
        "
      >
        <div
          class="my-1 py-2 px-3 flex items-center justify-center bg-red-50 text-red-500 w400 mx-auto border border-red-300 rounded"
        >
          <i class="menu-iconfont menu-icon-test1 mr-2 font20"></i>
          <span class="">{{ $t("oms.orderInfo.authorization") }}</span>
        </div>
      </div>

      <div class="refund-part"  v-if="isCancelOrder">
        <div

            class="py-2 px-4  mb-4"
            :class="isCancelOrder ? 'bg-white ' : ''"
        >
          <div
              class="my-1 py-2 px-3 flex items-center justify-center bg-gray-900 text-white w400 mx-auto rounded"
          >
            <i class="menu-iconfont menu-icon-test1 mr-2 font20"></i>
            <span class="">{{ $t("oms.orderInfo.cancelMsg") }}</span>
          </div>
        </div>
        <h5 class="refund-title">{{ $t("oms.orderInfo.refundStatus") }}</h5>
        <div class="refund-status-container flex flex-col justify-center items-start">
          <div class="step-item flex items-center justify-center">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-start.svg" />
            <div class="flex flex-col right-text color-active">
              <span class="step-title">{{$t("oms.orderInfo.refund.orderCancel.title")}}</span>
              <span class="step-date">{{ formatTime(props.detail.cancelledTime?.dbStamp) }}</span>
              <span class="step-desc">{{$t("oms.orderInfo.refund.orderCancel.desc")}}</span>
            </div>
          </div>
          <div class="step-item light flex items-center justify-center"
               v-if="orderRefundData.refundHeader.onLineRefund">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-submit.svg" />
            <div class="flex flex-col right-text color-active">
              <span class="step-title">{{$t("oms.orderInfo.refund.refundSubmit.title")}}</span>
              <span class="step-date">{{ formatTime(orderRefundData.refundHeader.submitDateTime.dbStamp) }}</span>
              <span class="step-desc">{{$t("oms.orderInfo.refund.refundSubmit.desc")}} {{
                  formatTime(orderRefundData.refundHeader.submitDateTime.dbStamp) }}</span>
            </div>
          </div>
          <div class="step-item flex items-center justify-center"
               v-if="orderRefundData.refundHeader.onLineRefund && orderRefundData.refundHeader.statusId === 'PENDING'">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-pending.svg" />
            <div class="flex flex-col right-text color-pending">
              <span class="step-title">{{$t("oms.orderInfo.refund.refundPending.title")}}</span>
              <span class="step-desc">{{$t("oms.orderInfo.refund.refundPending.desc")}}</span>
            </div>
          </div>
          <div class="step-item light flex items-center justify-center"
               v-if="orderRefundData.refundHeader.onLineRefund && orderRefundData.refundHeader.statusId === 'FAILED'">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-failure.svg" />
            <div class="flex flex-col right-text color-active">
              <span class="step-title">{{$t("oms.orderInfo.refund.refundFailure.title")}}</span>
              <span class="step-date">{{ formatTime(refundResult.statusDateTime.dbStamp) }}</span>
              <span class="step-desc">{{ refundResult.failedReason }}</span>
            </div>
          </div>
          <div class="step-item light flex items-center justify-center"
               v-if="orderRefundData.refundHeader.onLineRefund && orderRefundData.refundHeader.statusId === 'SUCCEEDED'">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-success.svg" />
            <div class="flex flex-col right-text color-active">
              <span class="step-title">{{$t("oms.orderInfo.refund.refundSuccess.title")}}</span>
              <span class="step-date">{{ formatTime(refundResult.statusDateTime.dbStamp) }}</span>
              <span class="step-desc">{{$t("oms.orderInfo.refund.refundSuccess.desc")}}</span>
            </div>
          </div>
          <div class="step-item light flex items-center justify-center" v-if="!orderRefundData.refundHeader.onLineRefund">
            <img src="https://static.heroes.horego.com/menu-pc/oms/refund-failure.svg" />
            <div class="flex flex-col right-text color-active">
              <span class="step-title">{{$t("oms.orderInfo.refund.refundManual.title")}}</span>
              <span class="step-date">{{ formatTime(refundResult.statusDateTime.dbStamp) }}</span>
              <span class="step-desc">{{ $t("oms.orderInfo.refund.refundManual.desc") }}</span>
            </div>
          </div>
        </div>
        <el-button class="refresh-btn flex items-center justify-center gap-2 cursor-pointer"
                v-if="orderRefundData.refundHeader.statusId === 'PENDING'"
                :loading="isBtnLoading"
                @click="onClickRefreshBtn">
          <el-icon><Refresh color="#000000"/></el-icon>
          <span>{{$t("oms.orderInfo.refund.refreshStatus")}}</span>
        </el-button>

        <h5 class="refund-title" :class="orderRefundData.refundHeader.statusId !== 'PENDING' ?'mt-8':''">{{
            $t("oms.orderInfo.buyerInfomation") }}</h5>
        <div class="contact-container flex items-center justify-between gap-2 cursor-pointer">
          <a class="refresh-btn flex items-center justify-center gap-3 mb-0" :href="`tel:${buyerPhone}`">
            <img class="contact-icon" src="https://static.heroes.horego.com/menu-pc/oms/contact-phone.svg" />
            <span>{{$t("oms.orderInfo.refund.callBuyer")}}</span>
          </a>
          <a class="refresh-btn flex items-center justify-center gap-3 cursor-pointer mb-0"
             :href="`https://wa.me/${buyerPhone}`" target="_blank">
            <img class="contact-icon" src="https://static.heroes.horego.com/menu-pc/oms/contact-whatsapp.svg" />
            <span>{{$t("oms.orderInfo.refund.whatsapp")}}</span>
          </a>
        </div>
      </div>
      <!-- 顶部数据 -->
      <div class="p-4">
        <div
          class="flex justify-between mb-2"
          v-for="item in state.topInfoList"
          :key="item.key"
        >
          <div class="text-sm text-gray-400">{{ item.key }}</div>
          <div
            :class="isCancelOrder ? 'text-gray-400' : 'text-gray-600'"
            class="text-sm"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
      <!-- 产品列表 -->
      <div
        class="bg-white py-6 pb-0 px-4"
        :class="isCancelOrder ? 'text-gray-400' : 'text-gray-700'"
      >
        <ProductItem
          v-for="item in productlist"
          :key="item.productId"
          :product="item"
          :orderType="item.serviceType"
          :isCancelOrder="isCancelOrder"
        ></ProductItem>
        <!-- <div
          v-for="(item, i) in productlist"
          :key="item.productId"
          class="flex justify-between mb-6"
        >
          <div class="flex">
            <p
              class="mr-5 text-lg flex-shrink-0"
              :class="isCancelOrder ? 'text-gray-400' : 'text-gray-700'"
            >
              {{ item.quantity }}x
            </p>
            <div>
              <h3
                class="text-lg font-bold"
                :class="isCancelOrder ? 'text-gray-400' : 'text-gray-700'"
              >
                {{ item.productName }}
              </h3>
              <p class="text-sm text-gray-400">{{ item.internalName }}</p>
              <div
                v-for="mixItem in item.modifiers"
                :key="mixItem.name"
                class="text-sm font-bold leading-6 w-full break-all"
              >
                · {{ mixItem.name }}
              </div>
              <div
                v-if="item.comment"
                class="text-sm font-bold leading-6 w-full break-all"
              >
                · {{ item.comment }}
              </div>
            </div>
          </div>
          <div class="text-lg flex-shrink-0">
            <p>
              {{
                productlist.length - i < detail.orderGiftItems?.length
                  ? "Gratis"
                  : $n(item.finalSingleTotal, "currency", "id-ID")
              }}
            </p>
            <p
              class="line-through text-gray-400"
              v-if="item.baseSingleTotal != item.finalSingleTotal"
            >
              {{ $n(item.baseSingleTotal, "currency", "id-ID") }}
            </p>
            <div class="text-right">
              <StatusMark
                v-if="detail.serviceType == 'FULL_DINE_IN'"
                :status="item.groupStatusId"
              >
              </StatusMark>
            </div>
          </div>
        </div> -->
      </div>
      <div v-if="detail.comment" class="border-t px-4 text-lg py-6 break-all">
        {{ $t("oms.orderInfo.comment") }}: {{ detail.comment }}
      </div>

      <!-- 下方数据列表 -->
      <div class="py-6 pb-0 px-4">
        <div class="flex justify-between mb-2">
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.orderValue") }}
          </div>
          <div class="text-sm text-gray-700">
            <div class="text-right">
              {{ $n(detail.itemSubTotal, "currency", "id-ID") }}
            </div>
            <div
              v-if="detail.tax.inclusive && detail.tax.rate"
              class="text-right text-sm text-gray-400"
            >
              {{ $t("oms.orderInfo.inclusiveTax") }}
              {{ detail.tax.rate }}%
              {{ $n(detail.tax.amount, "currency", "id-ID") }}
            </div>
          </div>
        </div>
        <div
          v-if="
            detail.promos &&
            detail.promos.length &&
            detail.promos[0]?.promoType != 'PROMO_GWP'
          "
          class="flex justify-between mb-2"
        >
          <div class="text-sm text-gray-400">
            {{ detail.promos && detail.promos[0]?.name }}
          </div>
          <div class="text-sm text-gray-700">
            <div class="text-right">
              {{
                $n(
                  detail.promos && detail.promos[0]?.amount,
                  "currency",
                  "id-ID"
                )
              }}
            </div>
          </div>
        </div>
        <!--  -->
        <div
          v-if="detail?.serviceCharge?.rate"
          class="flex justify-between mb-2"
        >
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.serviceFee") }}
            {{ detail.serviceCharge.rate }}%
          </div>
          <div class="text-sm text-gray-700">
            <div class="text-right">
              {{ $n(detail.serviceCharge.amount, "currency", "id-ID") }}
            </div>
          </div>
        </div>
        <div
          v-else-if="!detail?.serviceCharge?.rate && detail.serviceCharge.amount"
          class="flex justify-between mb-2"
        >
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.serviceFee") }}
          </div>
          <div class="text-sm text-gray-700">
            <div class="text-right">
              {{ $n(detail.serviceCharge.amount, "currency", "id-ID") }}
            </div>
          </div>
        </div>
        <!--  -->
        <div
          v-if="!detail.tax.inclusive && detail.tax.rate"
          class="flex justify-between mb-2"
        >
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.exclusiveTax") }}
            {{ detail.tax.rate }}%
          </div>
          <div class="text-sm text-gray-700">
            {{ $n(detail.tax.amount, "currency", "id-ID") }}
          </div>
        </div>

        <!--  -->
        <div v-if="detail.rounding" class="flex justify-between mb-2">
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.rounding") }}
          </div>
          <div class="text-sm text-gray-700">
            {{ $n(detail.rounding, "currency", "id-ID") }}
          </div>
        </div>
        <div class="flex justify-between">
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.transactionFee") }}
          </div>
          <div class="text-sm text-gray-700">
            -{{ $n(detail.transactionFee, "currency", "id-ID") }}
          </div>
        </div>
        <div
          v-if="detail.serviceType == 'DELIVERY'"
          class="flex justify-between mt-2"
        >
          <div class="text-sm text-gray-400">
            {{ $t("oms.orderInfo.shippingTotal") }}
          </div>
          <div class="text-sm text-gray-700">
            {{ $n(detail.shippingTotal, "currency", "id-ID") }}
          </div>
        </div>
        <!-- 最底部 -->
      </div>
    </div>
  </el-scrollbar>

  <div class="flex justify-between mt-4 px-4 pb-8">
    <div class="text-sm text-gray-400">
      {{ $t("oms.orderInfo.totalIncome") }}
    </div>
    <div
      class="text-lg font-bold"
      :class="[isCancelOrder ? 'text-gray-700' : 'text-sunflower-500']"
    >
      {{ $n(detail.income, "currency", "id-ID") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import dayjs from 'dayjs';
import { Refresh } from "@element-plus/icons-vue";
import { serviceTypeMap } from "@src/until/KEY_MAP";
import type { serviceTypeMapKey } from "@src/until/KEY_MAP";
import ProductItem from "./ProductItem.vue";

const { t } = useI18n();
const props = defineProps({
  detail: {
    type: Object as any,
  },
});
const emits = defineEmits({
  refresh:() => {}
})

const detail = props.detail;
const isBtnLoading = ref(false);

const tableNumber = computed(() => {
  if (["PICK_UP", "DELIVERY"].includes(detail.serviceType)) {
    return detail?.serialNumber;
  } else {
    return detail?.tableNumber;
  }
});

const formatTime = ($timestamp:string | number, $format="DD MMMM YYYY HH:mm A") => {
  if(!$timestamp) return "";
  return dayjs($timestamp).format($format)
}

const state = reactive({
  topInfoList: [
    { key: t("oms.orderInfo.orderId"), value: detail.orderId },
    {
      key: t("oms.orderInfo.orderTime"),
      value: formatTime(detail?.createdTime?.dbStamp,"DD MMM YYYY HH:mm:ss"),
    },
    {
      key: t("oms.orderInfo.orderType"),
      value: serviceTypeMap[detail.serviceType as serviceTypeMapKey],
    },
    {
      key: t("oms.orderInfo.tableNumber"),
      value: tableNumber,
    },
    {
      key: t("oms.orderInfo.buyerName"),
      value: detail.buyer?.address?.toName || detail.buyer?.nickName,
    },

    {
      key: t("oms.orderInfo.paymentDisplayName"),
      value:
        (detail?.payments && detail?.payments[0]?.paymentDisplayName) ||
        (detail?.payments && detail?.payments[0]?.paymentMethodName) ||
        t("oms.orderInfo.unpaid"),
    },

    {
      key: t("oms.orderInfo.buyerPhone"),
      value: detail.buyer?.address?.phone || detail.buyer?.phone,
    },
  ],
});
if (props.detail.serviceType == "DELIVERY") {
  state.topInfoList.push(
    {
      key: t("oms.orderInfo.driverName"),
      value: detail.shipment.driverName,
    },
    {
      key: t("oms.orderInfo.driverPhone"),
      value: detail.shipment.driverPhone,
    }
  );
}

const productlist = computed(() => {
  const temp: any = [];
  detail.orderGroups?.forEach((element: any) => {
    temp.push(...element.orderItems);
  });
  detail.orderGiftItems?.forEach((element: any) => {
    element.myType = "gift";
    temp.push(element);
  });
  return temp;
});

const buyerPhone = computed(() => {
  if(props.detail.buyer.phone) {
    return props.detail.buyer.phone.replace("+", "");
  }
  return "";
})

const orderRefundData = computed(() => {
  if(Array.isArray(props.detail.payments) && props.detail.payments.length > 0) {
    return props.detail.payments[0].orderRefund;
  }
  return {
    "refundActive": false,
    refundHeader: {
      submitDateTime:0
    }
  }
})

const refundResult = computed(() => {
 let finalResult = orderRefundData.value.refundHistory.find((item: { statusId: any; }) => item.statusId ===
      orderRefundData.value.refundHeader.statusId);
 if(!finalResult) {
   finalResult = {
     statusDateTime:{dbTime: 0}
   }
 }
 return finalResult;
})

const isCancelOrder = computed(() => {
  if(Array.isArray(props.detail.payments) && props.detail.payments.length > 0) {
    return props.detail.payments[0].orderRefund?.refundActive === true;
  }
  return false;
});


const onClickRefreshBtn = () => {
  isBtnLoading.value = true;
  emits("refresh");
  setTimeout(() => {
    isBtnLoading.value = false;
  }, 1500)
}

</script>
<style lang="scss" scoped>
.max-h-770 {
  max-height: 470px;
}
.refund-part {
  padding: 20px 16px;

  .refund-title {
    color: #232323;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; /* 112.5% */
    margin-bottom: 16px;
  }
  .refund-status-container {
    margin-bottom: 16px;
    img {
      width: 36px;
      height: 36px;
    }
    .step-item {
      margin-bottom: 48px;
      position: relative;
      gap: 8px;
      &::before {
        content: "";
        width: 1px;
        height: 32px;
        position: absolute;
        top: -40px;
        left: 18px;
        background-color: #bdbdbd;
        overflow: hidden;
      }
      &.light {
        &::before {
          background-color: #232323;
        };

      }
      &:first-child {
        &::before {
          width: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .color-active {
      color: #232323;
    }
    .color-pending {
      color: #bdbdbd;
    }
    .step-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.42px;
    }
    .step-date {
      color: #9E9E9E;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 180% */
      letter-spacing: -0.3px;
    }
    .step-desc {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: -0.36px;
    }
  }
  .refresh-btn {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid var(--Grey-300, #D1D5DB);
    background: var(--White, #FFF);
    margin-bottom: 40px;

    color: #232323;
    /* body Jakarta */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    &.mb-0 {
      margin-bottom: 0;
    }
  }
}
</style>
